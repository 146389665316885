/* Base styles */
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

.gallery-container {
    margin: 0 auto;
    padding: 3rem; /* Increased padding */
    text-align: center;
    margin-top: 12rem;
    background-color: #f9f9f9;
    min-height: calc(100vh - 6rem - 4rem); /* Replace with actual navbar/footer heights */
  }
  
  .gallery-container h1 {
    font-size: 4.5rem;
    margin-bottom: 1rem;
    font-family: 'Playfair Display', serif; /* Example font */
    color: #333; /* Optional: Dark gray for elegance */
    text-transform: uppercase; /* Optional: Capitalize for emphasis */
    letter-spacing: 2px; /* Optional: Add spacing between letters */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Optional: Subtle shadow effect */
}

  
.gallery-container p {
    font-size: 2.2rem;
    margin-bottom: 2rem;
    font-family: 'Crimson Text', serif; /* Elegant serif font */
    color: #555; /* Subtle gray color for a softer look */
    line-height: 1.6; /* Improved readability */
    font-style: italic; /* Add a touch of elegance */
}
  
  /* Grid layout */
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    padding: 0 2rem;
  }
  
  /* Gallery item */
  .gallery-item {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .gallery-item:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .gallery-image {
    width: 100%;
    height: 600px;
    object-fit: cover;
  }
  
  .gallery-details {
    padding: 1rem;
  }
  
  .gallery-details h3 {
    font-size: 3.5rem;
    font-family: 'Crimson Text', serif;
    margin-bottom: 0.5rem;
  }
  
  .gallery-details p {
    font-size: 2rem;
    color: #555;
    line-height: 1.5;
    font-style: italic;
  }
  
  .gallery-video {
    width: 100%;
    height: 600px; /* You can adjust the height as needed */
    object-fit: cover;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .gallery-container {
      padding: 1rem;
    }
  
    .gallery-grid {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }
  
  @media (max-width: 480px) {
    .gallery-grid {
      grid-template-columns: 1fr;
    }
  
    .gallery-item {
      margin: 0 auto;
    }
  }
  