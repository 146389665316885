footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    min-height: 30rem; /* Use min-height instead of height */
    background-color: black;
}

.footer-info p {
    color: white;
}

.footer-links {
    display: flex;
    gap: 3rem;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 2rem;
}

.footer-links button {
    background: none;
    border: none;
    color: white;
    text-decoration: none;
    font-size: 1.8rem;
    cursor: pointer;
}

.footer-links button:hover {
    text-decoration: underline;
}

.footer-social-media {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
}

.footer-social-media a {
    color: white;
    font-size: 2rem;
    transition: color 0.3s;
}

.footer-social-media a:hover {
    color: #ddd;
}

.love {
    color: white;
    font-size: 1.7rem;
    margin-top: 3rem;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
    .footer-links {
        flex-direction: column;
        gap: 1rem;
    }

    .footer-links button {
        font-size: 1.5rem;
    }

    .footer-social-media {
        flex-direction: row;
        gap: 1rem;
    }

    .footer-social-media a {
        font-size: 1.5rem;
    }

    .love {
        font-size: 1.5rem;
    }
}
