.navbar {
    -webkit-box-shadow: 0px 4px 5px -5px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 0px 4px 5px -5px rgba(0, 0, 0, 0.23);
    box-shadow: 0px 4px 5px -5px rgba(0, 0, 0, 0.23);
    background-color: white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 12rem;
    transition: height 0.1s linear;
}

.nav-container.cont-sticky {
    height: 8rem !important;
}

.logo-img {
    width: 12rem !important; /* Force the width */
    height: auto;
    cursor: pointer;
}


.nav-links {
    font-size: 1.8rem;
    text-transform: uppercase;
    display: flex;
    gap: 2.5rem;
}

.nav-links a {
    color: #000000;
    text-decoration: none;
}

.nav-links a:hover {
    text-decoration: underline;
}

.nav-links i {
    cursor: pointer;
}

/* Hamburger menu */
.hamburger-menu {
    display: none;
    gap: 3rem;
}

.hamburger-hamb,
.hamburger-cart {
    font-size: 2.3rem;
    display: none;
    cursor: pointer;
}

.mobile-nav-full {
    background-color: #F3F3F3;
    z-index: 200;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.open-flex {
    display: flex !important;
}

.closed-flex {
    display: none !important;
}

.mobile-nav-full i {
    font-size: 2.3rem;
    position: absolute;
    right: 25px;
    top: 49px;
    cursor: pointer;
}

.mobile-links {
    font-size: 3rem;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    top: 44%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.mobile-links a {
    color: black;
    text-decoration: none;
}

.mobile-links a:hover {
    text-decoration: underline;
}

/* Cart */
.cart-div {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    padding: 3rem 2.5rem 1.5rem 2.5rem;
    background-color: white;
    height: 100vh;
    width: 50rem;
    transition: all 0.4s ease;
}

.page-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000075;
    z-index: 888;
    transition: all 0.3s ease-in;
}

.cart-title-btn {
    font-size: 1.7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-full-h2 {
    font-size: 2.1rem !important;
}

.cart-title-btn i {
    font-size: 2.5rem;
    cursor: pointer;
}

.cart-body {
    height: 100vh;
    padding: 0.5rem;
}

/* Cart icon styles */
/* Cart icon styles for desktop */
.cart-icon {
    position: relative;
    cursor: pointer;
  }
  
  /* Pseudo-element for desktop quantity indicator */
  .cart-icon::after {
    content: '';
    top: -18px;
    position: absolute;
    left: 12px;
    font-size: 13px;
    background-color: #B6002C;
    color: white;
    padding: 5px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  
  .cart-icon.with-items::after {
    content: attr(data-array-length);
  }
  
  /* Hide cart-quantity in desktop */

  /* Styles for mobile */
  @media (max-width: 600px) {
    .hamburger-menu {
      display: flex; /* Show hamburger menu on mobile */
    }
  
    .hamburger-cart {
      display: flex; /* Ensure it is visible */
      position: relative; /* Important for positioning the quantity */
    }
  
    .cart-quantity-mobile {
      display: flex; /* Ensure it's displayed on mobile */
      position: absolute; /* Position relative to the cart icon */
      top: -10px; /* Adjust as needed */
      right: -10px; /* Adjust as needed */
      background: #B6002C; /* Ensure visibility */
      color: white;
      border-radius: 50%;
      padding: 1.5px 6px;
      font-size: 12px;
      z-index: 1000; /* Ensure it's on top */
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  