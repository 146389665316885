.page-container-privacy {
  margin: 0 auto;
  padding: 3rem;
  text-align: center;
  margin-top: 12rem;
  background-color: white;
  min-height: calc(100vh - 6rem - 4rem); /* Replace with actual navbar/footer heights */
}


.page-content p {
  margin-bottom: 15px;
  line-height: 1.6;
  text-align: left;
  font-size: 2rem;
}

.page-content h2 {
  font-size: 2.5rem;
}

.link {
  text-decoration: underline;
}