.confirmation-page {
    max-width: 800px;
    margin: 2rem auto;
    padding: 1.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 15rem;
  }
  
  .confirmation-success, .confirmation-failure {
    text-align: center;
  }

  .confirmation-success p {
    font-size: 1.5rem;
  }
  
  .confirmation-success h1 {
    font-size: 4.5rem;
  }
  
  h1 {
    color: #2c3e50;
    margin-bottom: 1rem;
  }
  
  p {
    color: #4d4d4d;
    margin: 0.5rem 0;
  }

  .shipping-info p {
    font-size: 2.5rem;
  }
  
  .order-summary, .shipping-info {
    margin-top: 2rem;
    padding: 1rem;
    border-top: 2px solid #ddd;
  }
  
  .order-summary h3, .shipping-info h3 {
    color: #3498db;
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }
  
  .order-products-list {
    list-style-type: none;
    padding: 1rem;
  }
  
  .product-item {
    display: flex;
    align-items: flex-start;
    padding: 1rem 1rem;
    border-bottom: 1px solid #ddd;
  }
  
  .product-img img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 1rem;
    border-radius: 4px;
  }
  
  .product-info p {
    margin: 0.25rem 0;
  }
  
  .go-back-home-btn {
    display: inline-block;
    padding: 1.5rem;
    background-color: #3498db;
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .go-back-home-btn:hover {
    background-color: #2980b9;
  }
  
  .loading, .error-message, .no-session {
    text-align: center;
    padding: 2rem;
    font-size: 1.25rem;
    color: #555;
  }
  
  .error-message {
    color: #e74c3c;
  }
  