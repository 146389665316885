.news {
    background-color: #191919;
    height: 28rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.news h2 {
    color: white;
    font-size: 3.5rem;
    margin-bottom: 2rem;
}

.news-text {
    text-align: center;
}

.news input {
    font-size: 1.7rem;
    padding: .7rem;
    margin-right: .7rem;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
}

.news input:focus {
    border-color: #fff;
}

.news button {
    font-size: 1.7rem;
    padding: .7rem;
    border: none;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.news button:hover {
    background-color: #0056b3;
}

.news button:active {
    background-color: #004080;
}

.news .newsletter-message {
    margin-top: 1rem;
    font-size: 1.5rem;
    padding: 0.5rem;
    border-radius: 5px;
}

.news .newsletter-message.success {
    color: #28a745;
    background-color: #d4edda;
}

.news .newsletter-message.error {
    color: #dc3545;
    background-color: #f8d7da;
}

@media (max-width: 400px) {
    form {
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }

    .news input {
        margin-right: 0;
        text-align: center;
    }
}
