p {
    color: black;
}

.product-page-div {
    padding-top: 14rem;
    margin-top: 10rem;
}

.product-size {
    margin: 1rem 0;
    font-size: 2.5rem;
    font-weight: 600;
    justify-content: center;
  }

  .product-size p {
    margin-bottom: 0.5rem;
  }
  
  .size-buttons {
    display: flex; /* Display buttons side by side */
    gap: 0.5rem; /* Space between buttons */
  }
  
  .size-buttons button {
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f2f2f2;
    font-weight: 600; /* Adjust font weight */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .size-buttons button:hover {
    background-color: #ddd;
  }
  
  .size-buttons button.selected {
    background-color: #007bff; /* Selected button color */
    color: white; /* Selected button text color */
  }

  .product-color {
    margin: 1rem 0;
    font-size: 2.5rem;
    font-weight: 600;
    justify-content: center;
  }

  .product-color p {
    margin-bottom: 0.5rem;
  }
  
  .color-buttons {
    display: flex; /* Display buttons side by side */
    gap: 0.5rem; /* Space between buttons */
    flex-wrap: wrap; /* Allow buttons to wrap onto the next line */
}

  
  .color-buttons button {
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f2f2f2;
    font-weight: 600; /* Adjust font weight */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .color-buttons button:hover {
    background-color: #ddd;
  }
  
  .color-buttons button.selected {
    background-color: #007bff; /* Selected button color */
    color: white; /* Selected button text color */
  }
.product-div {
    /* height: 100%; */
    margin-bottom: 10rem;
    width: 100%;
    display: flex;
    position: relative;
}

.product-big-name {
    position: absolute;
    font-size: 3.5rem;
    top: -50px;
    left: 50%;
    transform: translate(-50%);
}

.product-left {
    width: 50%;
    height: 100%;
    padding-top: 5rem;
}

.big-img {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: center;
    margin-left: 1rem;
}

.big-img img {
    width: 75%;
    height: 100%;
    object-fit: cover;
}

.small-imgs {
    width: 100%;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
    align-items: center;
    padding: 3rem 2rem;
}

.small-imgs img {
    width: 22%;
    height: 20%;
    object-fit: cover;
    cursor: pointer;
}

.small-imgs img:hover {
    outline: 1px solid rgba(0, 0, 0, 0.3);

    -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.18);
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.18);
}

.product-right {
    width: 50%;
    height: 100%;
    background-color: #E5E5E5;
    padding: 16rem 5rem 8rem 5rem;
}

.product-spec {
    font-size: 2rem;
    margin-bottom: 5rem;
    line-height: 1.5;
}

.product-quant {
    display: flex;
    justify-content: space-between;
    font-size: 2.5rem;
    margin-bottom: 5rem;
    font-weight: 600;
    align-items: center;
}

.product-btns {
    display: flex;
    border: 1px solid black;
}

.product-btns button {
    font-size: 3rem;
    width: 5.5rem;
    height: 6rem;
    color: black;
    cursor: pointer;
    background-color: white;
    border: none;
    transition: all 0.2s;
}

.product-btns button:first-child {
    border-right: 1px solid black;
}

.product-btns button:last-child {
    border-left: 1px solid black;
}

.product-btns button:hover {
    background-color: transparent;
}

.product-btns p {
    outline-offset: -2px;
    width: 6rem;
    height: 5rem;
    text-align: center;
    padding-top: 10px;
}

.atc-buy {
    display: flex;
    gap: 2rem;
    padding-left: px;
}

.atc-buy button {
    width: 100%;
    height: 5.5rem;
    border: none;
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s;
}

.atc-btn {
    background-color: transparent;
    border: 2px solid black !important;
}

.atc-btn:hover {
    color: white;
    background-color: black;
}

.buy-btn {
    background-color: #B6002C;
    color: white;
    border: 2px solid #B6002C !important;
}

.buy-btn:hover {
    background-color: transparent;
    color: #B6002C;
}

.specifications {
    display: flex;
    gap: 3rem;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10rem;
}

.spec {
    background-color: #E5E5E5;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    overflow-y: auto; /* Add vertical scrolling */
    max-height: 400px; /* Set a maximum height to avoid overlap */
    border: 1px solid #ccc; /* Optional: Add a border for a better look */
}

.spec::-webkit-scrollbar {
    width: 8px; /* Scrollbar width */
}

.spec::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar thumb color */
    border-radius: 4px;
}

.spec::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Scrollbar thumb hover color */
}

.spec-title {
    font-size: 2.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.review {
    margin-bottom: 1rem;
    padding: 1rem;
    border-bottom: 1px solid #ddd;
}

.reviewer-name-date {
    display: flex;
    align-items: center;  /* Vertically align items */
    justify-content: space-between;  /* Distribute space between items */
}

.reviewer-date {
    flex-grow: 1; /* Allow elements to grow and fill the available space */
    text-align: right;
}

.no-reviews {
    font-size: 2rem;
}

.reviewer-name {
    font-weight: bold;
    margin-bottom: 0.2rem;
    font-size: 2.5rem;
}

.reviewer-date {
    font-weight: bold;
    margin-bottom: 0.2rem;
    font-size: 2.5rem;
}

.review-rating {
    color: #FFD700; /* Gold color for stars */
    margin-bottom: 0.5rem;
}

.review-comment {
    font-style: italic;
    line-height: 1.5;
    font-size: 1.5rem;
}

.title-desc {
    font-size: 1.65rem;
}

/* Adjustments for mobile responsiveness */
@media (max-width: 570px) {
    .specifications {
        gap: 2rem;
        flex-direction: column;
    }
    
    .spec {
        max-height: 300px; /* Reduce height for smaller screens */
    }
}


/* notify */

.notify {
    color: white;
    background-color: #373737;
    padding: 1.2rem 2.2rem;
    z-index: 999999999;
    font-size: 2rem;
    position: absolute;
    transform: translate(-50%);
    top: -100%;
    left: 50%;
}

.notify p {
    color: white;
}

.notify.slide-in {
    animation-duration: 4s;
    animation-delay: -1s;
    animation-name: slidein;
    animation-direction: alternate;
    animation-fill-mode: forwards;
}

.trustpilot-widget {
    margin-top: -10px; /* Adjust the value as needed to move it up */
}

.product-rating {
    margin: 1rem 0;
    font-size: 2rem;
    font-weight: 100;
    justify-content: center;
}

.star {
    font-size: 30px; /* Adjust size as needed */
    color: rgb(165, 150, 63); /* Change color to your preference */
  }
  
  .star.filled {
    color: orange; /* Different color for filled stars */
  }  

@keyframes slidein {
    0% {
        top: -100%;
    }

    40% {
        top: 90px;
    }

    60% {
        top: 90px;
    }

    100% {
        top: -100%;
    }
}

@media (max-width:900px) {
    .product-right {
        padding: 16rem 2rem 8rem 2rem;
    }
    .trustpilot-widget {
        margin-top: 0px; /* Adjust the value as needed to move it up */
    }
}

@media (max-width:850px) {
    .product-div {
        flex-direction: column;
        align-items: center;
        gap: 3rem;
        margin-bottom: 6rem;

    }

    .product-right {
        width: 100%;
        padding: 3rem 2rem 4rem 2rem;
    }

    .product-left {
        width: 100%;
    }

    .product-big-name {
        width: 31rem;
        text-align: center;
    }

    .trustpilot-widget {
        margin-top: 5px; /* Adjust the value as needed to move it up */
    }
}

@media (max-width:570px) {

    .specifications {
        gap: 2rem;
        flex-direction: column;
        margin-bottom: 28rem;
    }
}

@media (max-width:450px) {
    .product-quant {
        flex-direction: column;
        gap: 2rem;
    }

    .product-spec {
        margin-bottom: 2rem;
    }

    .atc-buy {
        gap: 2rem;
        width: 100%;
        flex-direction: column;
    }

    .atc-buy button {
        width: 100%;
    }


}
