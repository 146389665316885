.cart-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #ddd;
  }
  
  .cart-img img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .cart-item-info {
    flex-grow: 1;
    padding-left: 1.5rem;
  }
  
  .cart-item-description {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: black;
  }
  
  .cart-item-price {
    font-size: 1.2rem;
    /* font-weight: bold; */
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .quantity-controls {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    font-weight: bold;
  }
  
  .quantity-controls button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .quantity-controls button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  .quantity-controls span {
    margin: 0 1rem;
    font-size: 1.2rem;
  }
  
  .remove-btn {
    text-decoration: underline;
    padding: 0.75rem 2rem;
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #000000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .remove-btn:hover {
    background-color: #000000;
    transform: scale(1.05);
  }
  