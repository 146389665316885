.grid-container {
    display: grid;
    height: 50rem;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 'one two four' 'one two four-low';
    gap: 1.3rem;
    margin-top: 1.3rem;
}

.home-container {
    padding-top: 12rem;
}

.banner-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(45deg, #ff4e4e, #46b946);
    padding: 0; /* Remove padding to avoid gaps */
    border-bottom: 2px solid #b22222;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    height: 500px; /* Set a consistent height for the banner */
  }
  
  .banner-text {
    flex: 1;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding: 10px; /* Adjust padding for text spacing */
  }
  
  .banner-text h1 {
    color: white;
  }
  
  .banner-text p {
    color: white;
  }

  .banner-image {
    flex: 1;
    height: 100%; /* Match the height of the container */
  }
  
  .banner-image img {
    width: 100%; /* Stretch to fill container width */
    height: 100%; /* Stretch to fill container height */
    object-fit: cover; /* Ensure the image covers the area without distortion */
    display: block; /* Remove inline gaps caused by default image styling */
  }
  
  /* Responsive behavior for banner layout */
  @media (max-width: 1025px) {
    .banner-top {
      flex-direction: column; /* Stack text and image vertically */
      height: auto; /* Adjust height to fit content */
    }
  
    .banner-text {
      text-align: center; /* Ensure text is centered */
      padding: 20px; /* Add padding for spacing */
    }
  
    .banner-image {
      width: 100%; /* Ensure image spans the full width */
      height: auto; /* Allow height to adjust based on content */
    }
  
    .banner-image img {
      width: 100%; /* Ensure image fits container width */
      height: auto; /* Preserve aspect ratio */
      object-fit: contain; /* Prevent cropping */
    }
  }
  
  
  

.free-shipping-header {
    background-color: #f8e71c; /* Bright yellow background */
    text-align: center;
    padding: 10px;
    font-size: 1.5em;
    font-weight: bold;
    color: #333; /* Dark text for good contrast */
    border-bottom: 2px solid #333; /* Optional border for better separation */
    padding-top: 13rem;
  }

  .free-shipping-header p {
    color: black;
  }
  

.featured {
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.main-description {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    color: white;
    font-size: 3.8rem;
    font-weight: 600;
}

.featured img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
}

.grid-one {
    grid-area: one;
}

.grid-two {
    grid-area: two;
}

.grid-four {
    grid-area: four;
}

.grid-four-low {
    grid-area: four-low;
}

.lil-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in;
}

#img1:hover,
#img2:hover,
#img3:hover,
#img4:hover {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

@media (max-width: 750px) {
    .grid-container {
        height: 500px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas:
            "one two"
            "four four-low";
        grid-gap: 13px;
        gap: 13px;
    }
}

@media (max-width: 450px) {
    .main-description {
        bottom: 1rem;
        left: 1rem;
        font-size: 3rem;
    }
}

@media (max-width: 400px) {
    .main-description {
        bottom: 1rem;
        left: 0.5rem;
        font-size: 2.5rem;
    }
}
