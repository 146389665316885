.page-container-refund {
  margin: 0 auto;
  padding: 3rem;
  text-align: center;
  margin-top: 12rem;
  background-color: white;
  min-height: calc(100vh - 6rem - 4rem); /* Replace with actual navbar/footer heights */
  }

  
  .policy-content p {
    margin-bottom: 15px;
    line-height: 1.6;
    text-align: left;
    font-size: 3rem;
  }
  